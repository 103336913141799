import React from 'react'

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-16 h-16 relative"
      preserveAspectRatio="none"
    >
      <rect width="64" height="64" rx="32" fill="#99F6E4" />
      <path
        d="M29.0681 18.5553C29.8143 15.4816 34.1857 15.4816 34.9319 18.5553C35.4139 20.5409 37.6888 21.4832 39.4337 20.42C42.1348 18.7742 45.2258 21.8652 43.58 24.5663C42.5168 26.3112 43.4591 28.5861 45.4447 29.0681C48.5184 29.8143 48.5184 34.1857 45.4447 34.9319C43.4591 35.4139 42.5168 37.6888 43.58 39.4337C45.2258 42.1348 42.1348 45.2258 39.4337 43.58C37.6888 42.5168 35.4139 43.4591 34.9319 45.4447C34.1857 48.5184 29.8143 48.5184 29.0681 45.4447C28.5861 43.4591 26.3112 42.5168 24.5663 43.58C21.8652 45.2258 18.7742 42.1348 20.42 39.4337C21.4832 37.6888 20.5409 35.4139 18.5553 34.9319C15.4816 34.1857 15.4816 29.8143 18.5553 29.0681C20.5409 28.5861 21.4832 26.3112 20.42 24.5663C18.7742 21.8652 21.8652 18.7742 24.5663 20.42C26.3112 21.4832 28.5861 20.5409 29.0681 18.5553Z"
        stroke="#111827"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.25 32C37.25 34.8995 34.8995 37.25 32 37.25C29.1005 37.25 26.75 34.8995 26.75 32C26.75 29.1005 29.1005 26.75 32 26.75C34.8995 26.75 37.25 29.1005 37.25 32Z"
        stroke="#111827"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
