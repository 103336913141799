import React from 'react'
import {useTranslation} from 'react-i18next'
import Section03Card from './Section03Card'
import SVGEye from './svgs/SVGEye'
import SVGDoc from './svgs/SVGDoc'
import SVGRise from './svgs/SVGRise'
import SVGPresentation from './svgs/SVGPresentation'
import SVGCog from './svgs/SVGCog'
import SVGMoney from './svgs/SVGMoney'

interface Props {
  className?: string
}

function Page({className = ''}: Props) {
  const {t} = useTranslation()
  return (
    <section
      className={`w-full flex flex-col items-center ${className}`}
    >
      <h2
        className="font-head font-extrabold md:text-5xl text-2xl uppercase text-gray-900
      md:max-w-3xl px-16"
      >
        {t('HOW WE CAN HELP YOU?')}
      </h2>
      <p className="md:text-lg text-sm px-5 text-center text-gray-500 max-w-5xl my-10">
        {t(
          'Our Compliance Tool can help you monitor trademark violations and identify Unauthorized PPC search Affiliate partners immediately so you can take action right away.'
        )}
      </p>

      <div className="w-full my-10 grid md:grid-cols-3">
        <Section03Card
          svg={<SVGEye />}
          title={t('title')}
          text={t(
            'Better control of your branded keyword terms so only your Authorized PPC Search Affiliates have\n' +
              '    access to them.'
          )}
        />
        <Section03Card
          svg={<SVGDoc />}
          title={t('title')}
          text={t(
            'Lower risk of losing your affiliate program’s budget to the unauthorized trademark bidders.'
          )}
        />
        <Section03Card
          svg={<SVGRise />}
          title={t('title')}
          text={t(
            'Improvement of your daily productivity as an affiliate program manager, with automated monitoring of branded keyword terms.'
          )}
        />
        <Section03Card
          svg={<SVGPresentation />}
          title={t('title')}
          text={t(
            'Positive reflection of your affiliate program to other departments in your organization.'
          )}
        />
        <Section03Card
          svg={<SVGCog />}
          title={t('title')}
          text={t(
            'Guaranteed control of your PPC Affiliates so you can avoid conflict with the Search Marketing Department in your organization.'
          )}
        />
        <Section03Card
          svg={<SVGMoney />}
          title={t('title')}
          text={t(
            'Unauthorized PPC Search Affiliates take thousands of dollars from your affiliate program, hence undermining your marketing efforts.'
          )}
        />
      </div>
    </section>
  )
}

export default Page
