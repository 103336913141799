import React from 'react'

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-16 h-16"
      preserveAspectRatio="none"
    >
      <rect width="64" height="64" rx="32" fill="#BAE6FD" />
      <path
        d="M37.25 32C37.25 34.8995 34.8995 37.25 32 37.25C29.1005 37.25 26.75 34.8995 26.75 32C26.75 29.1005 29.1005 26.75 32 26.75C34.8995 26.75 37.25 29.1005 37.25 32Z"
        stroke="#111827"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3019 31.9999C17.5319 24.9 24.1649 19.75 32.0008 19.75C39.8367 19.75 46.4697 24.9001 48.6996 32.0001C46.4697 39.1 39.8367 44.25 32.0008 44.25C24.1649 44.25 17.5319 39.0999 15.3019 31.9999Z"
        stroke="#111827"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
