import React from 'react'

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-16 h-16 relative"
      preserveAspectRatio="none"
    >
      <rect width="64" height="64" rx="32" fill="#FEE2E2" />
      <path
        d="M26.75 40.75V37.25M32 40.75V33.75M37.25 40.75V30.25M40.75 47.75H23.25C21.317 47.75 19.75 46.183 19.75 44.25V19.75C19.75 17.817 21.317 16.25 23.25 16.25H33.0251C33.4893 16.25 33.9344 16.4344 34.2626 16.7626L43.7374 26.2374C44.0656 26.5656 44.25 27.0107 44.25 27.4749V44.25C44.25 46.183 42.683 47.75 40.75 47.75Z"
        stroke="#111827"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
