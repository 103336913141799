import React from 'react'

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-16 h-16 relative"
      preserveAspectRatio="none"
    >
      <rect width="64" height="64" rx="32" fill="#DDD6FE" />
      <path
        d="M40.75 26.75V23.25C40.75 21.317 39.183 19.75 37.25 19.75H19.75C17.817 19.75 16.25 21.317 16.25 23.25V33.75C16.25 35.683 17.817 37.25 19.75 37.25H23.25M26.75 44.25H44.25C46.183 44.25 47.75 42.683 47.75 40.75V30.25C47.75 28.317 46.183 26.75 44.25 26.75H26.75C24.817 26.75 23.25 28.317 23.25 30.25V40.75C23.25 42.683 24.817 44.25 26.75 44.25ZM39 35.5C39 37.433 37.433 39 35.5 39C33.567 39 32 37.433 32 35.5C32 33.567 33.567 32 35.5 32C37.433 32 39 33.567 39 35.5Z"
        stroke="#111827"
        strokeWidth="3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
