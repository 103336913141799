import React from 'react'

export default function () {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-16 h-16 relative"
      preserveAspectRatio="none"
    >
      <rect width="64" height="64" rx="32" fill="#FDE68A" />
      <path
        d="M18 18V16.5H16.5V18H18ZM46 18H47.5V16.5H46V18ZM22.1893 30.9393C21.6036 31.5251 21.6036 32.4749 22.1893 33.0607C22.7751 33.6464 23.7249 33.6464 24.3107 33.0607L22.1893 30.9393ZM28.5 26.75L29.5607 25.6893C29.2794 25.408 28.8978 25.25 28.5 25.25C28.1022 25.25 27.7206 25.408 27.4393 25.6893L28.5 26.75ZM33.75 32L32.6893 33.0607C32.9706 33.342 33.3522 33.5 33.75 33.5C34.1478 33.5 34.5294 33.342 34.8107 33.0607L33.75 32ZM41.8107 26.0607C42.3964 25.4749 42.3964 24.5251 41.8107 23.9393C41.2249 23.3536 40.2751 23.3536 39.6893 23.9393L41.8107 26.0607ZM23.9393 46.6893C23.3536 47.2751 23.3536 48.2249 23.9393 48.8107C24.5251 49.3964 25.4749 49.3964 26.0607 48.8107L23.9393 46.6893ZM32 40.75L33.0607 39.6893C32.7794 39.408 32.3978 39.25 32 39.25C31.6022 39.25 31.2206 39.408 30.9393 39.6893L32 40.75ZM37.9393 48.8107C38.5251 49.3964 39.4749 49.3964 40.0607 48.8107C40.6464 48.2249 40.6464 47.2751 40.0607 46.6893L37.9393 48.8107ZM16.25 16.5C15.4216 16.5 14.75 17.1716 14.75 18C14.75 18.8284 15.4216 19.5 16.25 19.5V16.5ZM47.75 19.5C48.5784 19.5 49.25 18.8284 49.25 18C49.25 17.1716 48.5784 16.5 47.75 16.5V19.5ZM18 19.5H46V16.5H18V19.5ZM44.5 18V39H47.5V18H44.5ZM44.25 39.25H19.75V42.25H44.25V39.25ZM19.5 39V18H16.5V39H19.5ZM19.75 39.25C19.6119 39.25 19.5 39.1381 19.5 39H16.5C16.5 40.7949 17.9551 42.25 19.75 42.25V39.25ZM44.5 39C44.5 39.1381 44.3881 39.25 44.25 39.25V42.25C46.0449 42.25 47.5 40.7949 47.5 39H44.5ZM24.3107 33.0607L29.5607 27.8107L27.4393 25.6893L22.1893 30.9393L24.3107 33.0607ZM27.4393 27.8107L32.6893 33.0607L34.8107 30.9393L29.5607 25.6893L27.4393 27.8107ZM34.8107 33.0607L41.8107 26.0607L39.6893 23.9393L32.6893 30.9393L34.8107 33.0607ZM26.0607 48.8107L33.0607 41.8107L30.9393 39.6893L23.9393 46.6893L26.0607 48.8107ZM30.9393 41.8107L37.9393 48.8107L40.0607 46.6893L33.0607 39.6893L30.9393 41.8107ZM16.25 19.5H47.75V16.5H16.25V19.5Z"
        fill="#111827"
      />
    </svg>
  )
}
